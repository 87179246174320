import React from 'react';
import {
    AcademicCapIcon,
    BuildingOffice2Icon,
    LightBulbIcon,
    RocketLaunchIcon
} from "@heroicons/react/24/outline";

function App() {
    return (
        <div className="bg-gray-100 font-sans leading-normal tracking-normal">
            <header className="bg-violet-950 text-white p-6">
                <div className="container mx-auto">
                    <h1 className="text-4xl font-bold">Ian Deveseleer</h1>
                    <p className="text-lg">Ingénieur Tests & automatisation (QA) | Solocal</p>
                </div>
            </header>

            <section className="py-10 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="flex items-center space-x-3 mb-6">
                        <BuildingOffice2Icon className="h-8 w-8 text-violet-950"/>
                        <h2 className="text-2xl font-bold">Expériences</h2>
                    </div>
                    <ul className="space-y-8">
                        <li>
                            <h3 className="text-xl font-semibold text-violet-950">
                                Ingénieur Tests (QA) - <a href="https://www.solocal.com" rel="noreferrer" target="_blank">Solocal</a> - <span
                                className="text-blue-600"><a href="https://www.pagesjaunes.fr" rel="noreferrer" target="_blank">www.pagesjaunes.fr</a></span>
                            </h3>
                            <p className="text-sm text-gray-500">16 décembre 2019 - Présent</p>
                            <p className="text-gray-700 mt-2">
                                Support aux équipes produits, développement et maintenance de l'outillage
                                mis à disposition pour l'automatisation des tests, maintenance de l'infrastructure de
                                tests
                                automatisés mise en place dans une chaîne de déploiement continu.
                            </p>
                        </li>
                        <li>
                            <h3 className="text-xl font-semibold text-violet-950">
                                Technicien QA - <a href="https://www.kereval.com" rel="noreferrer" target="_blank">Kereval</a>
                            </h3>
                            <p className="text-sm text-gray-500">Septembre 2016 - Décembre 2019</p>
                            <p className="text-gray-700 mt-2">
                                <strong>Stage de fin d'études :</strong> Mise à jour de l'automatisation des tests sur
                                un logiciel de gestion des tests d'interopérabilité des systèmes de santé (IHE - Gazelle)
                            </p>
                            <p className="text-gray-700 mt-2">
                                <strong>Mission de 3 ans chez PagesJaunes :</strong> Support aux équipes, développement
                                et maintenance de l'outillage de tests.
                            </p>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="py-10 bg-white">
                <div className="container mx-auto px-4">
                    <div className="flex items-center space-x-3 mb-4">
                        <LightBulbIcon className="h-8 w-8 text-violet-950"/>
                        <h2 className="text-2xl font-bold">Compétences</h2>
                    </div>
                    <div className="mb-6">
                        <h3 className="font-bold text-lg mb-2">Languages</h3>
                        <ul className="list-disc ml-6 grid grid-cols-2 gap-x-4">
                            <li>Java</li>
                            <li>TypeScript via Angular notamment</li>
                            <li>Spring / Springboot</li>
                            <li>Quelques bases de Dart via Flutter notamment</li>
                        </ul>
                    </div>

                    <div className="mb-6">
                        <h3 className="font-bold text-lg mb-2">Outils QA</h3>
                        <ul className="list-disc ml-6">
                            <li>Selenium</li>
                            <li>Fluentlenium</li>
                            <li>Rest Assured</li>
                        </ul>
                    </div>

                    <div className="mb-6">
                        <h3 className="font-bold text-lg mb-2">Outils Cloud</h3>
                        <ul className="list-disc ml-6 grid grid-cols-2 gap-x-4">
                            <li>Docker</li>
                            <li>Kubernetes</li>
                            <li>Kustomize</li>
                        </ul>
                    </div>

                    <div className="mb-6">
                        <h3 className="font-bold text-lg mb-2">Bases de données</h3>
                        <ul className="list-disc ml-6 grid grid-cols-2 gap-x-4">
                            <li>Mongo (Atlas et Atlas Search)</li>
                            <li>Firestore</li>
                        </ul>
                    </div>

                    <div className="mb-6">
                        <h3 className="font-bold text-lg mb-2">Outils</h3>
                        <ul className="list-disc ml-6">
                            <li>Git</li>
                            <li>Gitlab CI</li>
                            <li>Jenkins</li>
                            <li>Maven</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="py-10 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="flex items-center space-x-3 mb-4">
                        <AcademicCapIcon className="h-8 w-8 text-violet-950"/>
                        <h2 className="text-2xl font-bold">Formations</h2>
                    </div>
                    <ul>
                        <li className="mb-6">
                            <h3 className="text-xl font-semibold">Licence professionnelle TQL (Test et Qualité du
                                Logiciel)</h3>
                            <p className="text-sm">
                                <a href="https://www.univ-lemans.fr/fr/index.html" rel="noreferrer" target="_blank">Le Mans
                                    Université</a> - Laval (53)
                            </p>
                            <p className="text-sm text-gray-500">Diplôme obtenu en 2016</p>
                        </li>
                        <li className="mb-6">
                            <h3 className="text-xl font-semibold">Brevet de technicien supérieur (BTS), Services
                                informatiques aux organisations - Solutions Logicielles et Applications Métiers</h3>
                            <p className="text-sm">
                                <a href="https://beaupeyrat.fr/" rel="noreferrer" target="_blank">Institution Beaupeyrat</a> - Limoges
                                (87)
                            </p>
                            <p className="text-sm text-gray-500">Diplôme obtenu en 2015</p>
                        </li>
                        <li className="mb-6">
                            <h3 className="text-xl font-semibold">Baccalauréat professionnel - Systèmes Electroniques
                                Numériques - Télécommunications et Réseaux</h3>
                            <p className="text-sm">
                                <a href="https://www.lycee-maryse-bastie.fr/" rel="noreferrer" target="_blank">Lycée Maryse Bastié</a> -
                                Limoges (87)
                            </p>
                            <p className="text-sm text-gray-500">Diplôme obtenu en 2013</p>
                        </li>
                        <li className="mb-6">
                            <h3 className="text-xl font-semibold">Brevet d'études professionnelles - Systèmes
                                Electroniques Numériques - Télécommunications et Réseaux</h3>
                            <p className="text-sm">
                                <a href="https://www.lycee-maryse-bastie.fr/" rel="noreferrer" target="_blank">Lycée Maryse Bastié</a> -
                                Limoges (87)
                            </p>
                            <p className="text-sm text-gray-500">Diplôme obtenu en 2012</p>
                        </li>
                    </ul>
                </div>
            </section>

            <footer className="bg-violet-950 text-white p-6">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 Ian Deveseleer</p>
                    <div className="flex items-center justify-center space-x-2 mt-2">
                        <RocketLaunchIcon className="h-5 w-5 text-white"/>
                        <span>Propulsé par React et TailwindCSS</span>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
